<template>
    <el-main>
        <el-button style="margin-bottom: 10px" @click="add" size="small" type="primary" icon="el-icon-plus">添加预约</el-button>
        <el-form class="el-form-search" label-width="120px">
            <el-form-item label="预约用户：">
                <el-input size="small" placeholder="请输入预约用户" v-model="form.nick_name"></el-input>
            </el-form-item>
            <el-form-item label="预约人：">
                <el-input size="small" placeholder="请输入预约人姓名/手机号" v-model="form.order_word"></el-input>
            </el-form-item>
            <el-form-item label="预约到店时间：">
                <el-date-picker v-model="form.time" size="small" type="daterange" range-separator="~"
                    start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp"></el-date-picker>
            </el-form-item>
            <el-form-item label="服务名称：">
                <el-input size="small" placeholder="请输入服务名称" v-model="form.service_name" @input="input4"></el-input>
            </el-form-item>
            <el-form-item label="支付方式：">
                <el-select v-model="form.pay_type" size="small" placeholder="请选择">
                    <el-option v-for="item in pay_type" :key="item.val" :label="item.label" :value="item.val"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="订单状态：">
                <el-select v-model="form.status" size="small" placeholder="请选择">
                    <el-option v-for="item in status" :key="item.val" :label="item.label" :value="item.val"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label=" " label-width="45px">
                <el-button type="primary" size="small" @click="search">搜索</el-button>
                <!-- <el-button size="small">导出</el-button> -->
                <el-button plain size="small" @click="cancelSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>

        <!-- <el-tabs type="card" v-model="form.status">
      <el-tab-pane :name="item.name" v-for="item in tabs" :key="item.name">
           <span slot="label">{{item.label}}（0）</span>
      </el-tab-pane>
    </el-tabs> -->

        <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
            <template slot="empty">
                <No />
            </template>
            <el-table-column label="预约用户" min-width="120">
                <template v-slot="{ row }">
                    <div class="row">
                        <el-image :src="row.avatar" class="avatar" v-if="row.avatar"></el-image>
                        <div>{{ row.nick_name }}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="服务主图" width="160" align="center">
                <template v-slot="{ row }">
                    <el-image :src="row.images" class="s_image" :preview-src-list="new Array(row.images)"></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="service_name" label="服务名称" min-width="120">
            </el-table-column>
            <el-table-column label="预约人" width="160">
                <template v-slot="{ row }">
                    <div class="row">
                        <div class="row_left">
                            <div>{{ row.name }}</div>
                            <div>{{ row.mobile }}</div>
                        </div>
                        <el-popover :append-to-body="false" placement="top-start" trigger="hover"
                            v-if="row.order_person_arr.length > 0">
                            <div style="color:#409EFF;">共计{{ row.order_person_arr.length }}人</div>
                            <i class="el-icon-warning icon" slot="reference"></i>
                        </el-popover>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="预约到店时间" align="center" min-width="160">
                <template v-slot="{ row }">
                    <div>{{ row.order_time | getDateformat }}</div>
                </template>
            </el-table-column>
            <el-table-column label="实际到店时间" align="center" min-width="160">
                <template v-slot="{ row }">
                    <div v-if="row.realiy_time">{{ row.realiy_time | getDateformat }}</div>
                    <div v-else>--</div>
                </template>
            </el-table-column>
            <el-table-column prop="pay_type" label="支付方式" align="center">
                <template v-slot="{ row }">{{ row.order_type | pay_type_text }}</template>
            </el-table-column>
            <el-table-column prop="order_status" label="订单状态" align="center">
                <template v-slot="{ row }"><el-link :type="row.status | status_type" :underline="false"
                        style="cursor: default;">{{ row.status | status_text }}</el-link></template>
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="200">
                <template v-slot="{ row }">
                    <div class="btn">
                        <template v-if="row.status == 10">
                            <el-button type="text" size="small" @click="toshop(row.id)">确认到店</el-button>
                            <el-button type="text" size="small" @click="cancle(row.id)">取消</el-button>
                        </template>
                        <template v-else-if="row.status == 20">
                            <el-button type="text" size="small" @click="toCashier(row.id)">开单收款</el-button>
                            <el-button type="text" size="small" style="flex:2" v-if="row.pay_type == 1">完成开单</el-button>
                        </template>
                        <template v-else-if="row.status == 30">
                            <el-button type="text" size="small" @click="toDiagnosisForm(row.id)">添加诊断</el-button>
                            <el-button type="text" size="small" v-if="row.pay_type == 2">确认支付</el-button>
                        </template>
                        <div v-else></div>
                        <el-button type="text" size="small" @click="toDetail(row.id)">详情</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total_number" :page="form.page" :pageNum="form.rows" @updatePageNum="updateData"></Paging>

        <!-- 添加预约 -->
        <el-dialog title="添加预约" :visible.sync="dialogVisible" width="50%">
            <el-form label-width="140px" label-position="left" :model="reserveForm" :rules="rules" ref="reserveForm">
                <el-form-item label="预约人：" prop="name">
                    <el-input size="small" placeholder="请输入预约人姓名" v-model="reserveForm.name"
                        style="width:450px;"></el-input>
                </el-form-item>
                <el-form-item label="预约手机号：" prop="mobile">
                    <el-input size="small" placeholder="请输入预约手机号" v-model="reserveForm.mobile"
                        style="width:450px;"></el-input>
                </el-form-item>
                <el-form-item label="预约服务：" prop="service">
                    <el-cascader size="small" v-model="reserveForm.service" :props="props"
                        style="width:450px;"></el-cascader>
                </el-form-item>
                <el-form-item label="预约员工：" prop="staff_id">
                    <el-select size="small" v-model="reserveForm.staff_id" style="width:450px;">
                        <el-option v-for="item in staff_list" :key="item.id" :label="item.service_name"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="到店时间：" required>
                    <el-form-item prop="date" style="display:inline-block;margin-right:10px;">
                        <el-date-picker v-model="reserveForm.date" type="date" placeholder="选择日期" value-format="timestamp"
                            size="small" style="width:220px;"></el-date-picker>
                    </el-form-item>
                    <el-form-item prop="time" style="display:inline-block;">
                        <el-select v-model="reserveForm.time" size="small" style="width:220px;">
                            <el-option v-for="item in time_list" :key="item" :label="item" :value="item"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmForm">确 定</el-button>
            </span>
        </el-dialog>
    </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
export default {
    components: {
        Paging
    },
    data () {
        return {
            form: {
                page: 1,
                rows: 10,
                nick_name: '',
                order_word: '',
                time: '',
                service_name: '',
                pay_type: '',
                status: ''
            },
            total_number: 0,
            pay_type: [
                {
                    val: '',
                    label: '全部',
                },
                {
                    val: '1',
                    label: '在线支付',
                },
                {
                    val: '2',
                    label: '到店支付',
                },
            ],
            status: [
                {
                    val: '',
                    label: '全部'
                },
                {
                    val: 10,
                    label: '待到店'
                },
                {
                    val: 20,
                    label: '已到店'
                },
                {
                    val: 30,
                    label: '已开单'
                },
                {
                    val: 40,
                    label: '已完成'
                },
                {
                    val: 0,
                    label: '已取消'
                }
            ],
            list: [],

            dialogVisible: false,
            reserveForm: {
                name: '',
                mobile: '',
                service: '',
                staff_id: '',
                date: new Date(new Date().toDateString()).getTime(),
                time: ''
            },
            rules: {
                name: [{ required: true, message: '请输入预约人姓名', trigger: 'blur' }],
                mobile: [{ required: true, message: '请输入预约手机号', trigger: 'blur' }],
                service: [{ required: true, message: '请选择服务', trigger: 'change' }],
                staff_id: [{ required: true, message: '请选择员工', trigger: 'change' }],
                date: [{ required: true, message: '请选择预约日期', trigger: 'change' }],
                time: [{ required: true, message: '请选择预约时间', trigger: 'change' }],
            },
            serve_list: [],
            props: {
                lazy: true,
                lazyLoad: (node, resolve) => {
                    const { level } = node;
                    if (level == 0) {
                        resolve(this.serve_list)
                    } else {
                        this.$axios.post(this.$api.beauty.service.serviceInfo, { id: node.value }).then(res => {
                            if (res.code == 0) {
                                let info = res.result
                                if (info.is_spec) {
                                    let arr = info.service_spec.map(item => {
                                        return {
                                            value: item.id,
                                            label: item.title,
                                            leaf: true
                                        }
                                    })
                                    resolve(arr)
                                } else {
                                    resolve([])
                                }
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                    }
                }
            },

            staff_list: [],
            time_list: [],
        };
    },
    created () {
        this.getList()
        this.getServeList()
        this.getStaffList()
        this.getSetInfo()
    },
    filters: {
        getDateformat: getDateformat,
        pay_type_text (val) {
            return val ? '在线支付' : '到店支付'
        },
        status_text (val) {
            switch (val) {
                case 10:
                    return '待到店'
                    break
                case 20:
                    return '已到店'
                    break
                case 30:
                    return '已开单'
                    break
                case 40:
                    return '已完成'
                    break
                default:
                    return '已取消'
                    break
            }
        },
        status_type (val) {
            switch (val) {
                case 10:
                    return 'danger'
                    break
                case 20:
                    return 'warning'
                    break
                case 30:
                    return 'warning'
                    break
                default:
                    return 'primary'
                    break
            }
        }
    },
    methods: {
        updateData (val, status) {
            if (status == 0) {
                this.form.rows = val;
                this.getList();
            } else {
                this.form.page = val;
                this.getList();
            }
        },
        getList () {
            let data = {
                page: this.form.page,
                rows: this.form.rows,
                nick_name: this.form.nick_name,
                order_word: this.form.order_word,
                service_name: this.form.service_name,
                pay_type: this.form.pay_type,
                status: this.form.status,
            }
            if (this.form.time) {
                data.starttime = this.form.time[0] / 1000
                data.endtime = this.form.time[1] / 1000
            }
            this.$axios.post(this.$api.beauty.serveList, data).then(res => {
                if (res.code == 0) {
                    this.total_number = res.result.total;
                    let list = res.result.list
                    list.forEach(item => {
                        item.order_person_arr = item.order_person ? JSON.parse(item.order_person) : []
                    })
                    this.list = list;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        search () {
            this.form.page = 1;
            this.getList();
        },
        cancelSearch () {
            this.form = {
                page: 1,
                rows: 10,
                nick_name: '',
                order_word: '',
                time: '',
                service_name: '',
                pay_type: '',
                status: ''
            };
            this.getList();
        },
        cancle (id) {
            this.$confirm('请确定取消该预约?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.$axios.post(this.$api.beauty.cancleServe, { id }).then(res => {
                        if (res.code == 0) {
                            this.$message.success('取消成功');
                            this.getList();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                })
                .catch(() => { });
        },
        toshop (id) {
            this.$confirm('请确定用户是否到店?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.$axios.post(this.$api.beauty.toshop, { id }).then(res => {
                        if (res.code == 0) {
                            this.$message.success('确认成功');
                            this.getList();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                })
                .catch(() => { });
        },
        add () {
            this.reserveForm = {
                name: '',
                mobile: '',
                service: '',
                staff_id: '',
                date: new Date(new Date().toDateString()).getTime(),
                time: ''
            }
            this.dialogVisible = true
        },
        getServeList () {
            this.$axios.post(this.$api.beauty.service.serviceList, {
                page: 1,
                rows: 10
            }).then(res => {
                if (res.code == 0) {
                    let total_number = res.result.total_number
                    if (total_number > 10) {
                        this.$axios.post(this.$api.beauty.service.serviceList, {
                            page: 1,
                            rows: total_number
                        }).then(ress => {
                            if (ress.code == 0) {
                                this.serve_list = ress.result.list.map(item => {
                                    return {
                                        value: item.id,
                                        label: item.service_name
                                    }
                                })
                            } else {
                                this.$message.error(ress.msg);
                            }
                        });
                    } else {
                        this.serve_list = res.result.list.map(item => {
                            return {
                                value: item.id,
                                label: item.service_name
                            }
                        })
                    }
                }
            });
        },
        getStaffList () {
            this.$axios.post(this.$api.beauty.stafflist, {
                page: 1,
                rows: 10
            }).then(res => {
                if (res.code == 0) {
                    let total_number = res.result.total
                    if (total_number > 10) {
                        this.$axios.post(this.$api.beauty.beauty.stafflist, {
                            page: 1,
                            rows: total_number
                        }).then(ress => {
                            if (ress.code == 0) {
                                this.staff_list = ress.result.list
                            } else {
                                this.$message.error(ress.msg);
                            }
                        });
                    } else {
                        this.staff_list = res.result.list
                    }
                }
            });
        },
        confirmForm () {
            this.$refs.reserveForm.validate((valid) => {
                if (valid) {
                    let data = {
                        name: this.reserveForm.name,
                        mobile: this.reserveForm.mobile,
                        staff_id: this.reserveForm.staff_id
                    }
                    data.service_id = this.reserveForm.service[0]
                    if (this.reserveForm.service[1]) {
                        data.service_spec_id = this.reserveForm.service[1]
                    }
                    let arr = this.reserveForm.time.substr(0, 5).split(':')
                    data.order_time = this.reserveForm.date / 1000 + arr[0] * 60 * 60 + arr[1] * 60
                    this.$axios.post(this.$api.beauty.addServe, data).then(ress => {
                        if (ress.code == 0) {
                            this.dialogVisible = false
                            this.getList()
                        } else {
                            this.$message.error(ress.msg);
                        }
                    });
                } else {
                    return false;
                }
            });
        },
        getSetInfo () {
            this.$axios.post(this.$api.beauty.setinfo).then(res => {
                if (res.code == 0) {
                    if (res.result) {
                        let info = res.result
                        this.time_list = JSON.parse(info.appointment_time_frame).map(item => {
                            let arr = item.map(t => getDateformat(t).split(' ')[1].substr(0, 5))
                            return arr[0] + ' - ' + arr[1]
                        })
                    }
                }
            });
        },
        toDetail (id) {
            this.$router.push({ path: '/reserve/reserveDetail', query: { id } })
        },
        toCashier (id) {
            let routeData = this.$router.resolve('/reserve/cashier?id=' + id);
            window.open(routeData.href, '_blank');
        },
        toDiagnosisForm (id) {
            this.$router.push({ path: '/reserve/diagnosisAdd?id=' + id })
        }
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;
}

.row {
    display: flex;
    align-items: center;

    .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;
    }

    .row_left {
        margin-right: 10px;
    }
}

.s_image {
    width: 70px;
    height: 70px;
}

.icon {
    font-size: 20px;
    color: #409eff;
}

.btn {
    display: flex;
    justify-content: space-between;
}

/deep/.el-dialog {
    min-width: 650px;
}</style>
